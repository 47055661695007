import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Listing from '@components/Listing';
import horizontal from '@assets/shape_border_horizontal.svg';
import vertical from '@assets/shape_border_vertical.svg';
import fsa from '@assets/fsa_hsa.svg';
import hipaa from '@assets/hipaa.svg';
import ssl from '@assets/ssl.svg';
import {
  oneOf,
  string,
  node,
  arrayOf,
  shape,
  bool,
} from 'prop-types';
import { HERO_IMAGE_VARIANT_KNEE_PAIN, HERO_IMAGE_VARIANT_PHONE_CHECK } from '@/src/common/consts/hero';
import SmsTag from '@components/smsTag';

const HeroListing = ({
  imageVariant,
  imageAlt,
  title,
  text,
  ctaButton,
  titleButtonText,
  list,
  showSmsTag,
  smsTagClassName,
  smsTagLine,
  smsDataToken,
  showCerts,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        kneePain: file(relativePath: { eq: "hero_referral.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        phoneCheck: file(relativePath: { eq: "hero2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );
  const imageData = data[imageVariant]?.childImageSharp?.fluid;

  return (
    <section data-theme="ice-blue" id="top" style={{ overflow: 'visible' }}>
      <div className="container container--lg">
        <div className="grid flex--start">
          <div
            id="herotext"
            className="col col--12 col--sm-6 margin-top--lg margin-bottom--lg"
          >
            <h1 className="margin-bottom--sm text--lg">{title}</h1>
            {text && <p className="text--sm">{text}</p>}

            <Listing style={{ fontWeight: '400' }} listing={list} />

            <div className="flex--center">
              {titleButtonText && (
                <p className="margin-top--md margin-bottom--sm text--sm" style={{ maxWidth: '400px' }}>
                  {titleButtonText}
                </p>
              )}

              {ctaButton}

              {showSmsTag && (
                <div className={`${smsTagClassName}`}>
                  <SmsTag dataToken={smsDataToken} tagHeadline={smsTagLine} />
                </div>
              )}
            </div>
          </div>

          {imageData && (
            <BackgroundImage
              Tag="section"
              fluid={imageData}
              alt={imageAlt}
              className="col col--12 col--sm-6 fullwidth--sm hero--cover"
            >
              <img alt="horizont" className="sp hide--sm" src={horizontal} />
              <img alt="vertical" className="sp display--sm" src={vertical} />

              {showCerts && (
                <div className="certs flex flex--center-y">
                  <img alt="fsa_hsa" src={fsa} />
                  {' '}
                  <img
                    alt="hipaa"
                    className="margin-left--xxs margin-right--xxs"
                    src={hipaa}
                  />
                  {' '}
                  <img alt="ssl" src={ssl} />
                </div>
              )}
            </BackgroundImage>
          )}
        </div>
      </div>
    </section>
  );
};

HeroListing.defaultProps = {
  imageVariant: HERO_IMAGE_VARIANT_KNEE_PAIN,
  imageAlt: '',
  text: '',
  titleButtonText: '',
  list: [],
  ctaButton: undefined,
  showSmsTag: false,
  smsTagClassName: '',
  smsTagLine: '',
  smsDataToken: '',
  showCerts: false,
};

HeroListing.propTypes = {
  imageVariant: oneOf([HERO_IMAGE_VARIANT_KNEE_PAIN, HERO_IMAGE_VARIANT_PHONE_CHECK]),
  imageAlt: string,
  title: string.isRequired,
  text: string,
  ctaButton: node,
  titleButtonText: string,
  showSmsTag: bool,
  smsTagClassName: string,
  smsTagLine: string,
  smsDataToken: string,
  showCerts: bool,
  list: arrayOf(shape({
    li: string.isRequired,
  })),
};

export default HeroListing;
